import Image from "next/image";
import React from "react";

interface ErorTypes {
  errorTitle?: string;
}

const Error = ({ errorTitle }: ErorTypes) => {
  return (
    <div className="min-h-fit w-full py-20 gap-5 px-5 flex-center flex-col">
      <Image src="/images/error-image.jpg" alt="" width={130} height={130} />
      <p className="text-2xl md:text-3xl font-heroNew font-normal text-center">
        {errorTitle}
      </p>
    </div>
  );
};

export default Error;
