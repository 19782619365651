"use client";
import Image from "next/image";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../ui/carousel";
import { useState, useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Loading from "../ui/Loading";
import Error from "../ui/Error";
import { Button } from "../ui/button";
import { ShoppingCart } from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useCart } from '@/context/CartContext';

// Update the Product type
type Product = {
  id: number;
  name: string;
  description: string;
  summary: string;
  price: number;
  fake_price: number;
  bulletpoints: string[];
  origin: string;
  modal: string;
  product_Weight: number;
  length: number;
  width: number;
  height: number;
  stock: number;
  subCategoryId: number;
  categoryId: number;
  images: {
    id: string;
    url: string;
  }[];
};

const BestSellerOfMonth: React.FC = () => {
  const router = useRouter();
  const [products, setProducts] = useState<Product[]>([]);
  const [emblaRef] = useEmblaCarousel({ loop: true });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { addToCart, isLoading } = useCart();
  const handleView = async (e: React.MouseEvent, productId: number) => {
    e.preventDefault(); // Prevent the Link navigation
    try {
      router.push(`product/${productId}`)
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_API_URL}/v1/public/get-featured-products`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          console.log(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Fetched data:", JSON.stringify(data, null, 2));

        // Assuming the response contains an array of products
        if (Array.isArray(data)) {
          setProducts(data);
        } else if (data.products && Array.isArray(data.products)) {
          setProducts(data.products);
        } else {
          console.log("Invalid data structure received from API");
        }
        setLoading(false);
      } catch (error: any) {
        console.error("Error fetching products:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleAddToCart = async (e: React.MouseEvent, productId: number) => {
    e.preventDefault();  // Prevent the Link navigation
    e.stopPropagation(); // Stop event bubbling
    try {
      await addToCart(productId);
    } catch (error) {
      console.error('Error adding to cart:', error);
    }
  };

  // Render loading, error, or empty state
  if (loading) return <Loading />;
  if (error) return <Error errorTitle={error} />;
  if (products.length === 0)
    return <Error errorTitle="No special products found" />;

  return (
    <section className="bg-gradient-to-b from-white to-gray-50 py-16" id="best-seller">
      <main className="max-w-[1440px] m-auto px-5">
        <div className="w-full flex-between flex-col gap-12">
          <div className="text-center gap-5">
            <h1 className="text-3xl md:text-5xl font-bold font-heroNew text-black">
              Best Seller of the Month
            </h1>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Discover our most popular products loved by customers
            </p>
          </div>
          <div className="w-full">
            <Carousel
              className="w-full"
              opts={{
                align: "start",
                loop: true,
              }}
            >
              <CarouselContent className="w-full">
                {products.map((product) => (
                  <CarouselItem
                    key={product.id}
                    className="md:basis-1/2 lg:basis-1/3"
                  >
                    <div className="h-[420px] w-full max-w-[300px] mx-auto flex flex-col bg-white rounded-xl overflow-hidden transition-all duration-300 hover:shadow-xl hover:scale-[1.02] border border-gray-100">
                      <Link href={`/product/${product.id}`}>
                        <div className="relative h-[200px] w-full group">
                          <Image
                            src={product.images[0]?.url || "/placeholder.png"}
                            alt={product.name}
                            fill
                            className="object-cover group-hover:scale-105 transition-transform duration-300"
                            sizes="(max-width: 300px) 100vw, 300px"
                          />
                          <div className="absolute top-3 right-3 bg-[#F7553E] text-white px-3 py-1.5 rounded-full text-xs font-semibold shadow-lg">
                            Best Seller
                          </div>
                        </div>
                      </Link>

                      <div className="p-5 flex flex-col gap-3 flex-grow">
                        <Link href={`/product/${product.id}`}>
                          <h2 className="text-xl font-heroNew font-bold text-gray-800 line-clamp-1">
                            {product.name}
                          </h2>
                          <p className="text-gray-600 text-sm line-clamp-2">
                            {product.summary}
                          </p>
                        </Link>

                        <div className="mt-auto space-y-4">
                          <div className="flex justify-between items-center">
                            <span className="text-sm text-[#4B5563] bg-gray-100 px-3 py-1 rounded-full">
                              Stock: {product.stock}
                            </span>
                            <div className="flex flex-col items-end">
                              <span className="text-sm line-through text-gray-400">
                                ₹{product.fake_price}
                              </span>
                              <span className="text-xl font-bold text-[#F7553E]">
                                ₹{product.price}
                              </span>
                            </div>
                          </div>

                          <Button 
                            className="w-full bg-[#F7553E] hover:bg-[#E64A35] text-white py-2 rounded-lg shadow-sm hover:shadow-md transition-all"
                            onClick={(e) => handleView(e, product.id)}
                            disabled={isLoading}
                          >
                            <ShoppingCart className="mr-2 h-4 w-4" /> 
                            {isLoading ? 'Adding...' : 'View'}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </CarouselItem>
                ))}
              </CarouselContent>

              <CarouselPrevious className="absolute -left-4 bg-white shadow-xl hover:bg-gray-50 border-none" />
              <CarouselNext className="absolute -right-4 bg-white shadow-xl hover:bg-gray-50 border-none" />
            </Carousel>
          </div>
        </div>
      </main>
    </section>
  );
};

export default BestSellerOfMonth;
