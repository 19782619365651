import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/public/images/amirali-mirhashemian-RCVIlSXhYI0-unsplash.jpg");
;
import(/* webpackMode: "eager" */ "/app/public/images/madelynn-woods-LSrsbY8IKSg-unsplash.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/LandingSection/BestSellerSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/LandingSection/CategorySection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/LandingSection/HeroSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/LandingSection/SpecialOfTheMonthSection.tsx");
