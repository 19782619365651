"use client";

import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import Link from "next/link";
import Image from "next/image";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";

const HeroSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageData, setImageData] = useState<
    | {
        url: string;
        type: string;
      }[]
    | null
  >(null);

  const images = imageData ? imageData.map((image) => image.url) : [];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(timer);
  }, [images.length]);

  useEffect(() => {
    // Add API fetch
    const fetchImages = async () => {
      try {
        const response = await fetch(
          "https://api.marutibakersmart.com/v1/public/images/shop"
        );
        const result = await response.json();
        if (result.status === "success") {
          setImageData(
            Array.isArray(result.data)
              ? result.data
              : [
                  {
                    url: result.data.url,
                    type: result.data.type,
                  },
                ]
          );
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []); // Added new useEffect for API call

  useEffect(() => {
    // GSAP animations
    const image = document.querySelector(".box");
    const headings = document.querySelectorAll(".heading");
    const button = document.querySelector(".hero-button");

    if (image) {
      gsap.fromTo(
        image,
        { opacity: 0, y: -50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          onComplete: () => {
            gsap.to(image, {
              y: 10,
              duration: 1,
              repeat: -1,
              yoyo: true,
              ease: "power1.inOut",
            });
          },
        }
      );
    }

    if (headings.length) {
      gsap.fromTo(
        headings,
        { opacity: 0, y: -50 },
        {
          opacity: 1,
          y: 0,
          duration: 2,
          stagger: 0.2,
          ease: "power2.out",
        }
      );
    }

    if (button) {
      gsap.fromTo(
        button,
        { scale: 0.8, opacity: 0 },
        {
          scale: 1,
          opacity: 1,
          duration: 2,
          ease: "elastic.out(1, 0.3)",
          delay: 0.5,
        }
      );
    }
  }, []);

  return (
    <section className="w-full">
      <main className="max-w-[1380px] m-auto">
        <div className="min-h-fit lg:h-[70vh] pt-4 lg:pt-10 w-full flex-between flex-col lg:flex-row px-5 gap-20 lg:gap-0">
          <div className="flex-[1] w-full flex flex-col gap-10 items-start justify-center pt-20 lg:pt-0">
            <div className="flex flex-col gap-5">
              <h1 className="text-3xl md:text-5xl lg:text-6xl font-HeroNew font-bold heading max-w-[500px]">
                Where Quality Ingredients Rise to Perfection!
              </h1>
            </div>
            <Link
              href={"/shop"}
              className="py-2 px-3 border border-black text-black hover:bg-black hover:text-white text-sm font-HeroNew font-bold rounded-full hero-button duration-300"
            >
              SHOP NOW
            </Link>
          </div>
          <div className="flex-[1] w-full flex-center">
            {imageData && (
              <div className="w-full flex-center">
                {images.map((src, index) => (
                  <Image
                    key={src}
                    src={src}
                    width={1920}
                    height={1080}
                    alt={`Slide ${index + 1}`}
                    className="w-full max-w-[350px]"
                    priority={index === 0}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </main>
    </section>
  );
};

export default HeroSection;
