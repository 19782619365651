'use client'

import Image from "next/image"
import { useState, useEffect } from "react"
import axios from "axios"
import Loading from "../loading"
import Error from "../ui/Error"
import Link from "next/link"

interface Category {
  id: string
  name: string
  summary: string
  images: {
    url: string
  }[]
}

export default function CategorySection() {
  const [categories, setCategories] = useState<Category[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.NEXT_PUBLIC_API_URL}/v1/admin/get-categories`
        )
        setCategories(response.data)
      } catch (error) {
        setError((error as Error).message)
      } finally {
        setLoading(false)
      }
    }

    fetchCategories()
  }, [])

  if (loading) return <Loading />
  if (error) return <p>{error}</p>
  if (categories.length === 0) return <Error errorTitle="Error 404: Category not found" />

  return (
    <section id="category" className="py-16 px-4">
      <div className="max-w-[1440px] mx-auto">
        <h1 className="text-3xl md:text-4xl font-bold text-center mb-12">
          Shop by Category
        </h1>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6 justify-items-center">
          {categories.map((category) => (
            <Link
              href={`/subcategories/${category.id}`}
              key={category.id}
              className="group flex flex-col items-center gap-3 w-full max-w-[200px]"
            >
              <div className="relative w-full aspect-square rounded-full p-1 bg-black/30">
                <div className="relative w-full h-full rounded-full overflow-hidden border-4 border-white">
                  <Image
                    src={category.images[0]?.url || "/placeholder.png"}
                    alt={category.name}
                    fill
                    className="object-cover group-hover:scale-110 transition-transform duration-300"
                  />
                </div>
              </div>
              <h2 className="text-center text-sm font-medium">
                {category.name}
              </h2>
            </Link>
          ))}
        </div>
      </div>
    </section>
  )
}

